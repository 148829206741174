/* eslint-disable */
import { useStaticQuery, graphql } from "gatsby";

const useNeoGeoPortfolio = () => {
  const query = useStaticQuery(graphql`
    query neoGeoPortfolio {
      poster1: file(relativePath: { eq: "neo-geo/portfolio/01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster2: file(relativePath: { eq: "neo-geo/portfolio/02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster3: file(relativePath: { eq: "neo-geo/portfolio/03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster4: file(relativePath: { eq: "neo-geo/portfolio/04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster5: file(relativePath: { eq: "neo-geo/portfolio/05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster6: file(relativePath: { eq: "neo-geo/portfolio/06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster7: file(relativePath: { eq: "neo-geo/portfolio/07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster8: file(relativePath: { eq: "neo-geo/portfolio/08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster9: file(relativePath: { eq: "neo-geo/portfolio/09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster10: file(relativePath: { eq: "neo-geo/portfolio/10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster11: file(relativePath: { eq: "neo-geo/portfolio/11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poster12: file(relativePath: { eq: "neo-geo/portfolio/12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const portfolio = Object.values(query);

  const data = [
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53572",
      src: portfolio[0].childImageSharp.fluid,
      author: "Наталья Багрянцева",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa535722",
      src: portfolio[1].childImageSharp.fluid,
      author: "Губарева Полина",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53573",
      src: portfolio[2].childImageSharp.fluid,
      author: "Татьяна Попова",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53574",
      src: portfolio[3].childImageSharp.fluid,
      author: "Ксения Колесниченко",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53576",
      src: portfolio[5].childImageSharp.fluid,
      author: "Глеб Веселовский",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53575",
      src: portfolio[4].childImageSharp.fluid,
      author: "Наталья Грачёва",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53577",
      src: portfolio[6].childImageSharp.fluid,
      author: "Александр Потапов",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53579",
      src: portfolio[7].childImageSharp.fluid,
      author: "Мария Осовитняя",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53513",
      src: portfolio[11].childImageSharp.fluid,
      author: "Дарья Калинина",
      course: "Графсистема Воздух",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53510",
      src: portfolio[8].childImageSharp.fluid,
      author: "Артём Косенков",
      course: "Графсистема Нео-Гео",
    },

    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53511",
      src: portfolio[9].childImageSharp.fluid,
      author: "Мила Кулькова",
      course: "Графсистема Нео-Гео",
    },
    {
      id: "b07ab2e3-7b08-532a-a6c5-0a2a3fa53512",
      src: portfolio[10].childImageSharp.fluid,
      author: "Пакман Полина",
      course: "Графсистема Нео-Гео",
    },
  ];

  return data;
};

export default useNeoGeoPortfolio;
