/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "3587919",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "3587920",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "3587921",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "3587923",
  },
];
export default {
  NeoGeoExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle:
        "Комбинация элементов и принципов по определённым правилам.",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/neo-geo/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема Нео‑Гео?",
      explanationsTextTitle: "Нео‑Гео (Neo Geometry)",
      explanationsTextDescription:
        "Нео‑Гео — актуальная геометрия. В нём до абсурдного развивается идея швейцарских модулей — здесь правят плитки. И с помощью плиток можно рассказывать целые истории. Создавать свои маленькие миры. Эта графсистема подойдёт тем, кто мыслит абстрактными или вычищенными образами. Любит рисовать карты сокровищ. И обожает играться в конструктор Lego часами напролёт 😉",
    },
  ],
  priceRange,
};
